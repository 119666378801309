// Theme tokens based on https://system-ui.com/theme/

export interface Theme {
  colors: {
    primary: string;
    primaryLight: string;
    text: string;
    lightBg: string;
  };
  boxShadow: string;
}

export const BASE_COLOR = `#00b3a5`;

const colors = {
  primary: BASE_COLOR,
  primaryLight: '#e5f2eb',
  text: '#535d7e',
  lightBg: '#f4f7f8',
  border: '#dadaed',
};

const fontSizes = [
  '0.75rem', // 12px
  '0.875rem', // 14px
  '1.0625rem', // 17px
  '1.125rem', // 18px
  '1.25rem', // 20px
  '1.5rem', // 24px
  '1.7rem', // 28px
  '2rem', // 32px
  '2.25rem', // 36px
  '2.625rem', // 42px
];

const lineHeights = {
  solid: 1.25,
  dense: 1.5,
  default: 1.75,
};

// https://github.com/janczizikow/emotion-flex#theming
const grid = {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  maxWidths: {
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140,
  },
  gutters: 15,
};

const boxShadow = `0 10px 20px 0 rgba(0, 0, 0, 0.1)`;

export default { colors, fontSizes, lineHeights, boxShadow, ...grid };
