import { createContext } from 'react';

export interface IntlContextWrapper {
  locale: string;
  setLocale: (locale: string) => void;
}

export const IntlContext = createContext<IntlContextWrapper>({
  locale: 'en',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLocale: (l: string) => {},
});
