// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-de-anmeldung-bestaetigen-tsx": () => import("./../../../src/pages/de/anmeldung-bestaetigen.tsx" /* webpackChunkName: "component---src-pages-de-anmeldung-bestaetigen-tsx" */),
  "component---src-pages-de-anmeldung-bestaetigt-tsx": () => import("./../../../src/pages/de/anmeldung-bestaetigt.tsx" /* webpackChunkName: "component---src-pages-de-anmeldung-bestaetigt-tsx" */),
  "component---src-pages-de-we-care-mitmachen-tsx": () => import("./../../../src/pages/de/we_care/mitmachen.tsx" /* webpackChunkName: "component---src-pages-de-we-care-mitmachen-tsx" */),
  "component---src-pages-doctors-demo-tsx": () => import("./../../../src/pages/doctors-demo.tsx" /* webpackChunkName: "component---src-pages-doctors-demo-tsx" */),
  "component---src-pages-doctors-tsx": () => import("./../../../src/pages/doctors.tsx" /* webpackChunkName: "component---src-pages-doctors-tsx" */),
  "component---src-pages-email-change-bad-tsx": () => import("./../../../src/pages/email-change-bad.tsx" /* webpackChunkName: "component---src-pages-email-change-bad-tsx" */),
  "component---src-pages-email-change-confirmed-tsx": () => import("./../../../src/pages/email-change-confirmed.tsx" /* webpackChunkName: "component---src-pages-email-change-confirmed-tsx" */),
  "component---src-pages-email-change-expired-tsx": () => import("./../../../src/pages/email-change-expired.tsx" /* webpackChunkName: "component---src-pages-email-change-expired-tsx" */),
  "component---src-pages-emailalreadyconfirmed-tsx": () => import("./../../../src/pages/emailalreadyconfirmed.tsx" /* webpackChunkName: "component---src-pages-emailalreadyconfirmed-tsx" */),
  "component---src-pages-emailalreadyconfirmeddifferentaccount-tsx": () => import("./../../../src/pages/emailalreadyconfirmeddifferentaccount.tsx" /* webpackChunkName: "component---src-pages-emailalreadyconfirmeddifferentaccount-tsx" */),
  "component---src-pages-emailconfirmed-tsx": () => import("./../../../src/pages/emailconfirmed.tsx" /* webpackChunkName: "component---src-pages-emailconfirmed-tsx" */),
  "component---src-pages-emailnometadata-tsx": () => import("./../../../src/pages/emailnometadata.tsx" /* webpackChunkName: "component---src-pages-emailnometadata-tsx" */),
  "component---src-pages-emailnousername-tsx": () => import("./../../../src/pages/emailnousername.tsx" /* webpackChunkName: "component---src-pages-emailnousername-tsx" */),
  "component---src-pages-emailwronglink-tsx": () => import("./../../../src/pages/emailwronglink.tsx" /* webpackChunkName: "component---src-pages-emailwronglink-tsx" */),
  "component---src-pages-emailwrongtoken-tsx": () => import("./../../../src/pages/emailwrongtoken.tsx" /* webpackChunkName: "component---src-pages-emailwrongtoken-tsx" */),
  "component---src-pages-employers-tsx": () => import("./../../../src/pages/employers.tsx" /* webpackChunkName: "component---src-pages-employers-tsx" */),
  "component---src-pages-global-app-tsx": () => import("./../../../src/pages/global-app.tsx" /* webpackChunkName: "component---src-pages-global-app-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lp-tsx": () => import("./../../../src/pages/lp.tsx" /* webpackChunkName: "component---src-pages-lp-tsx" */),
  "component---src-pages-press-diga-launch-tsx": () => import("./../../../src/pages/press/diga-launch.tsx" /* webpackChunkName: "component---src-pages-press-diga-launch-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-reizdarm-tsx": () => import("./../../../src/pages/reizdarm.tsx" /* webpackChunkName: "component---src-pages-reizdarm-tsx" */),
  "component---src-pages-restoration-bad-tsx": () => import("./../../../src/pages/restoration-bad.tsx" /* webpackChunkName: "component---src-pages-restoration-bad-tsx" */),
  "component---src-pages-restoration-confirmed-tsx": () => import("./../../../src/pages/restoration-confirmed.tsx" /* webpackChunkName: "component---src-pages-restoration-confirmed-tsx" */),
  "component---src-pages-restoration-expired-tsx": () => import("./../../../src/pages/restoration-expired.tsx" /* webpackChunkName: "component---src-pages-restoration-expired-tsx" */),
  "component---src-pages-restorationalreadyconfirmed-tsx": () => import("./../../../src/pages/restorationalreadyconfirmed.tsx" /* webpackChunkName: "component---src-pages-restorationalreadyconfirmed-tsx" */),
  "component---src-pages-restorationconfirmed-tsx": () => import("./../../../src/pages/restorationconfirmed.tsx" /* webpackChunkName: "component---src-pages-restorationconfirmed-tsx" */),
  "component---src-pages-restorationnometadata-tsx": () => import("./../../../src/pages/restorationnometadata.tsx" /* webpackChunkName: "component---src-pages-restorationnometadata-tsx" */),
  "component---src-pages-restorationnousername-tsx": () => import("./../../../src/pages/restorationnousername.tsx" /* webpackChunkName: "component---src-pages-restorationnousername-tsx" */),
  "component---src-pages-restorationwronglink-tsx": () => import("./../../../src/pages/restorationwronglink.tsx" /* webpackChunkName: "component---src-pages-restorationwronglink-tsx" */),
  "component---src-pages-restorationwrongtoken-tsx": () => import("./../../../src/pages/restorationwrongtoken.tsx" /* webpackChunkName: "component---src-pages-restorationwrongtoken-tsx" */),
  "component---src-pages-rezepte-digainfo-tsx": () => import("./../../../src/pages/rezepte-digainfo.tsx" /* webpackChunkName: "component---src-pages-rezepte-digainfo-tsx" */),
  "component---src-pages-science-tsx": () => import("./../../../src/pages/science.tsx" /* webpackChunkName: "component---src-pages-science-tsx" */),
  "component---src-pages-zweckbestimmung-tsx": () => import("./../../../src/pages/zweckbestimmung.tsx" /* webpackChunkName: "component---src-pages-zweckbestimmung-tsx" */),
  "component---src-templates-blog-post-page-tsx": () => import("./../../../src/templates/blogPostPage.tsx" /* webpackChunkName: "component---src-templates-blog-post-page-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-static-page-tsx": () => import("./../../../src/templates/staticPage.tsx" /* webpackChunkName: "component---src-templates-static-page-tsx" */),
  "component---src-templates-video-detail-page-template-tsx": () => import("./../../../src/templates/videoDetailPageTemplate.tsx" /* webpackChunkName: "component---src-templates-video-detail-page-template-tsx" */),
  "component---src-templates-video-page-template-tsx": () => import("./../../../src/templates/videoPageTemplate.tsx" /* webpackChunkName: "component---src-templates-video-page-template-tsx" */),
  "component---src-templates-webview-page-tsx": () => import("./../../../src/templates/webviewPage.tsx" /* webpackChunkName: "component---src-templates-webview-page-tsx" */)
}

