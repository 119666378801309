import React, { useState } from 'react';
import { ThemeProvider } from 'emotion-theming';
import { IntlProvider } from 'react-intl';
import { ToastProvider } from 'react-toast-notifications';

// locale
import en from '../locales/en.json';
import de from '../locales/de.json';
import { IntlContext } from './IntlContext';

// theme
import theme from '../styles/theme';

// fonts
import '../assets/fonts/HeroNew/styles.css';
import '../assets/fonts/OpenSans/styles.css';
import '../assets/fonts/Recoleta/styles.css';

interface Props {
  children: React.ReactNode;
  pageContext: {
    locale?: 'en' | 'de';
  };
}

const messages = { en, de };

const Providers: React.FC<Props> = ({ children, pageContext: { locale } }) => {
  const [currentLocale, setLocale] = useState<string>(locale || 'en');
  return (
    <ToastProvider
      autoDismiss
      autoDismissTimeout={6000}
      placement="bottom-center"
    >
      <IntlContext.Provider value={{ locale: currentLocale, setLocale }}>
        <IntlProvider
          locale={locale || 'en'}
          messages={messages[locale || 'en']}
        >
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </IntlProvider>
      </IntlContext.Provider>
    </ToastProvider>
  );
};

export default Providers;
