import React from 'react';
import Providers from './src/components/Providers';

export const onClientEntry = () => {
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }

  return new Promise((resolve, reject) => {
    if (!Intl.PluralRules || !Intl.RelativeTimeFormat) {
      if (!Intl.PluralRules) {
        import('@formatjs/intl-pluralrules/polyfill').then(() =>
          Promise.all([
            import('@formatjs/intl-pluralrules/dist/locale-data/en'),
            import('@formatjs/intl-pluralrules/dist/locale-data/de'),
          ]).then(() => {
            if (!Intl.RelativeTimeFormat) {
              import('@formatjs/intl-pluralrules/polyfill').then(() =>
                Promise.all([
                  import(
                    '@formatjs/intl-relativetimeformat/dist/locale-data/en'
                  ),
                  import(
                    '@formatjs/intl-relativetimeformat/dist/locale-data/de'
                  ),
                ]).then(() => resolve())
              );
            } else {
              resolve();
            }
          })
        );
      }

      if (!Intl.RelativeTimeFormat) {
        import('@formatjs/intl-pluralrules/polyfill').then(() =>
          Promise.all([
            import('@formatjs/intl-relativetimeformat/dist/locale-data/en'),
            import('@formatjs/intl-relativetimeformat/dist/locale-data/de'),
          ]).then(() => resolve())
        );
      }
    } else {
      resolve();
    }
  });
};

export const wrapPageElement = ({ element, props }) => (
  <Providers {...props}>{element}</Providers>
);
